import type {
  AnyBulkWriteOperation,
  BulkWriteOptions,
  BulkWriteResult,
  Collection,
  CountDocumentsOptions,
  CreateCollectionOptions,
  Db,
  DeleteOptions,
  DeleteResult,
  Filter,
  FindOptions,
  InsertManyResult,
  InsertOneResult,
  MongoClient,
  OptionalId,
} from 'mongodb';

export enum MongoOperationType {
  READ,
  WRITE,
}

export interface MongoDBClient {
  connect(): Promise<MongoClient>;
  disconnect(): Promise<void>;
  execute(fn: (db: Db) => any, operationType: MongoOperationType): Promise<any>;
  executeInMemoryOperations(): Promise<void>;
}

export interface MongoDBService {
  client: MongoDBClient;
  count(
    collectionName: string,
    filter: Filter<any>,
    options?: CountDocumentsOptions,
  ): Promise<number>;
  find(
    collectionName: string,
    filter: Filter<any>,
    options?: FindOptions,
  ): Promise<any[]>;
  findOne(
    collectionName: string,
    filter: Filter<any>,
    options?: FindOptions,
  ): Promise<any>;
  insertOne(collectionName: string, values: any): Promise<InsertOneResult<any>>;
  insertMany(
    collectionName: string,
    docs: OptionalId<any>[],
    options?: BulkWriteOptions,
  ): Promise<InsertManyResult<any>>;
  deleteOne(
    collectionName: string,
    filter: Filter<any>,
    options?: DeleteOptions,
  ): Promise<DeleteResult>;
  deleteMany(
    collectionName: string,
    filter: Filter<any>,
    options?: DeleteOptions,
  ): Promise<DeleteResult>;
  createCollection(
    collectionName: string,
    options?: CreateCollectionOptions,
  ): Promise<Collection<any>>;
  bulkWrite(
    collectionName: string,
    operations: AnyBulkWriteOperation<any>[],
    options?: BulkWriteOptions,
  ): Promise<BulkWriteResult>;
}
