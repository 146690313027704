import { ApiFetcher, ApiEntityService } from '@april9/stack9-sdk';
import { AxiosInstance } from 'axios';
import React, { createContext, ReactNode, useContext, useMemo } from 'react';

import { AppConfig } from '../../models/AppConfig';

export interface AppOptions {
  fetcher: ApiFetcher;
  services: ApiEntityService;
  config: AppConfig;
}

export const AppContext = createContext({} as AppOptions);

interface AppProviderOptions {
  children: ReactNode;
  axiosFactory: (config: AppConfig) => AxiosInstance;
  config: AppConfig;
}

export function AppProvider({
  children,
  axiosFactory,
  config,
}: AppProviderOptions) {
  const axiosInstance = useMemo(
    () => axiosFactory(config),
    [axiosFactory, config],
  );

  const stack9Fetcher = useMemo(
    () => new ApiFetcher(axiosInstance),
    [axiosInstance],
  );

  const stack9Service = useMemo(
    () => new ApiEntityService(stack9Fetcher),
    [stack9Fetcher],
  );

  return (
    <AppContext.Provider
      value={{
        fetcher: stack9Fetcher,
        services: stack9Service,
        config,
      }}
    >
      {children}
    </AppContext.Provider>
  );
}

export const useStack9 = () => useContext(AppContext);
