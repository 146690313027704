import { LabelValue } from '../models/LabelValue';
import { getPropValue } from '../utils/formUtils';

export const useMapSourceLabelValue = (
  propNameValue: string,
  propNameLabel: string,
) => {
  const mapLabelValue = (item: any): LabelValue => ({
    value: item[propNameValue],
    label: getPropValue(propNameLabel, item) || '(Blank)',
  });

  const mapLabelValues = (sourceArray: Array<any>) => sourceArray.map<LabelValue>(mapLabelValue);

  return { mapLabelValue, mapLabelValues };
};
