export class SystemError extends Error {
  public status: number;

  public innerException: Error | undefined;

  public message: any;

  constructor(message: any, status?: number, innerException?: Error) {
    super(message);
    this.status = status || 400; // bad requests default;
    this.message = message;
    this.innerException = innerException; // TODO: this is exposing stack in production.
  }
}
