import { EntityInterface } from '@april9/stack9-sdk';
import { createContext } from 'react';

import { AuthState, initialAuthState } from './AuthState';

export interface AuthContextInterface<
  TUser extends EntityInterface = EntityInterface,
> extends AuthState<TUser> {
  loginWithRedirect: () => void;
  logout: () => void;
}

/**
 * @ignore
 */
const stub = (): never => {
  throw new Error('You forgot to wrap your component in <AuthProvider>.');
};

const initialContext: AuthContextInterface<any> = {
  ...initialAuthState,
  loginWithRedirect: stub,
  logout: stub,
};

const AuthContext = createContext(initialContext);

export default AuthContext;
