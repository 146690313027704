export enum HookTypes {
  function = 'function',
  workflow = 'workflow',
  message = 'message'
}

export enum HookHookTypes {
  validateAndTransform = 'ValidateAndTransformInput',
  afterChange = 'AfterChange',
}
