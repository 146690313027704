// WARNING:  this file sadly is duplicated server-side, check dateUtils.ts

import moment, { Moment } from 'moment';

import { Globalization } from '../models/Globalization';

const normaliseFormat = (
  dateFormat: string,
  timeFormat?: string,
  options = { moment: true },
) => {
  if (options.moment) {
    return `${dateFormat.replace('dd', 'DD').replace('yyyy', 'YYYY')}${
      timeFormat ? ` ${timeFormat}` : ''
    }`;
  }

  return `${dateFormat}${timeFormat ? ` ${timeFormat}` : ''}`;
};

const formatDateToString = (
  globalization: Globalization,
  dateValue: Moment | Date,
  time: boolean,
) => {
  if (!dateValue) {
    return '';
  }

  return time
    ? moment(dateValue)
        .local()
        .format(
          normaliseFormat(globalization.dateFormat, globalization.timeFormat),
        )
    : moment.utc(dateValue).format(normaliseFormat(globalization.dateFormat));
};

export { normaliseFormat, formatDateToString };
