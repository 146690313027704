export function setCookie(
  name: string,
  value: string,
  expDays = 1,
  path = '/',
  SameSite = 'Lax',
) {
  const date = new Date();
  date.setTime(date.getTime() + expDays * 24 * 60 * 60 * 1000);
  const expires = `expires=${date.toUTCString()}`;

  document.cookie = `${name}=${value}; ${expires}; path=${path};SameSite=${SameSite}`;
}

export function getCookie(cName: string) {
  const name = `${cName}=`;
  const decodedCookie = decodeURIComponent(document.cookie);
  const cookiesData = decodedCookie.split('; ');
  let response;

  cookiesData.forEach(val => {
    if (val.indexOf(name) === 0) response = val.substring(name.length);
  });

  return response;
}

export const deleteCookie = (name: string) => {
  document.cookie = `${name}=; Max-Age=-99999999;`;
};
