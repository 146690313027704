const defaultCulture = 'en-AU';

export const fromCurrency = (currencyFormat: string) => {
  const thousandSeparator = (1111).toLocaleString().replace(/1/g, '');
  const decimalSeparator = (1.1).toLocaleString().replace(/1/g, '');
  return parseFloat(
    currencyFormat
      .replace('$', '')
      .replace(new RegExp(`\\${thousandSeparator}`, 'g'), '')
      .replace(new RegExp(`\\${decimalSeparator}`), '.'),
  );
};

type CurrencyOptions = {
  hideSymbol?: boolean;
  noDecimal?: boolean;
  culture: string;
};

export const toCurrency = (
  value: number,
  options: CurrencyOptions = {
    hideSymbol: false,
    noDecimal: false,
    culture: defaultCulture,
  },
) => {
  const noDecimalCurrencyFormat = new Intl.NumberFormat(options.culture, {
    style: 'currency',
    currency: 'AUD',
    maximumFractionDigits: 0,
  });

  const currencyFormat = new Intl.NumberFormat(options.culture, {
    style: 'currency',
    currency: 'AUD',
  });

  const response = options.noDecimal
    ? noDecimalCurrencyFormat.format(value)
    : currencyFormat.format(value);

  return options.hideSymbol ? response.replace('$', '') : response;
};

export const toPercent = (value: number, culture = defaultCulture) => {
  const percentFormat = new Intl.NumberFormat(culture, {
    style: 'percent',
  });

  return percentFormat.format(value);
};

export const toDecimalGrouped = (value: number, culture = defaultCulture) => {
  const decimalFormat = new Intl.NumberFormat(culture, {
    style: 'decimal',
    useGrouping: true,
  });

  return decimalFormat.format(value);
};

export const toFloat = (value: string) => parseFloat(value || '0');
