import { BaseSearchResponse } from '../models/core/BaseSearchResponse';
import { EagerLoadingMethod } from '../models/core/EagerLoadingMethod';
import { MultiCriterion } from '../models/core/MultiCriterion';
import { S9Query } from '../models/core/S9Query';
import { S9QueryOptions } from '../models/core/S9QueryOptions';
import { ApiFetcher } from './ApiFetcher';

const QueryDefaultOptions: S9QueryOptions = {
  limit: 25,
  method: EagerLoadingMethod.withGraphJoined,
};

const buildSearchQuerystring = (
  { $groupBy }: S9Query,
  { page, limit, method }: S9QueryOptions,
) => {
  const hasGroupBy = $groupBy && $groupBy.length > 0;
  const pageParam = (!hasGroupBy && `&page=${page || 0}`) || '';
  const methodParam = (method && `&method=${method}`) || '';

  return `limit=${limit || 25}${pageParam}${methodParam}`;
};

export class ApiEntityServiceBase {
  constructor(protected fetcher: ApiFetcher) {}

  public async search<T>(
    entityName: string,
    query: S9Query,
    options: S9QueryOptions = QueryDefaultOptions,
  ) {
    return this.fetcher.post<BaseSearchResponse<T>>(
      `/api/${entityName}/search?${buildSearchQuerystring(query, options)}`,
      query,
    );
  }

  public async query<T>(
    entityName: string,
    query: S9Query,
    options: S9QueryOptions = QueryDefaultOptions,
  ) {
    return this.fetcher.post<BaseSearchResponse<T>>(
      `/api/${entityName}/query?${buildSearchQuerystring(query, options)}`,
      query,
    );
  }

  public async bulkSearch(
    queries: MultiCriterion,
    options: S9QueryOptions = QueryDefaultOptions,
  ) {
    return this.fetcher.post<Array<Array<any>>>(
      `/api/bulk-search?${buildSearchQuerystring({}, options)}`,
      queries,
    );
  }

  public async findFirst<T>(entityName: string, query: S9Query) {
    const { data, status } = await this.query<T>(entityName, query, {
      limit: 1,
      page: 0,
    });

    return { status, data: data.results[0] };
  }

  public async findOne<T>(entityName: string, query: S9Query) {
    const { data, status } = await this.query<T>(entityName, query, {
      limit: 1,
      page: 0,
    });

    if (data.total > 1) {
      throw new Error(
        "FindOne has returned more than 1 result. If you want to get the first record, use 'findFirst' instead.",
      );
    }

    return { status, data: data.results[0] };
  }

  public async get<T>(entityName: string, entityId: number) {
    return this.fetcher.get<T>(`/api/${entityName}/${entityId}`);
  }

  public async insert<T>(entityName: string, data: any) {
    return this.fetcher.post<{ id: number; entity: T }>(
      `/api/${entityName}`,
      data,
    );
  }

  public async update(entityName: string, entityId: number, data: any) {
    return this.fetcher.put<null>(`/api/${entityName}/${entityId}`, data);
  }

  public async moveWorkflow(
    entityName: string,
    entityId: number,
    action: string,
    data: any & { outcome_reason?: string },
  ) {
    return this.fetcher.post<null>(
      `/api/workflow/${entityName}/${entityId}/move/${action}`,
      data,
    );
  }

  public async takeWorkflowOwnership(
    entityName: string,
    entityId: number,
    action: string,
    data: any & { workflow_owner?: number },
  ) {
    return this.fetcher.post<null>(
      `/api/workflow/${entityName}/${entityId}/move/${action}`,
      data,
    );
  }

  public async save<T>(entityName: string, data: any, entityId?: number) {
    if (entityId) {
      return this.update(entityName, entityId, data);
    }

    return this.insert<T>(entityName, data);
  }

  public async delete(entityName: string, entityId: number) {
    return this.fetcher.delete(`/api/${entityName}/${entityId}`);
  }
}
