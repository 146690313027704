import type { SortDirection } from 'mongodb';

export enum EnumStatusNotification {
  ALL = 'all',
  UNSEEN = 'unseen',
  SEEN = 'seen',
}

export interface IUserNotification {
  entityType: string;
  entityId: number;
  userId: number;
  content: any;
  seen?: boolean;
  timestamp: Date;
}

export interface UserNotificationRequest {
  status?: EnumStatusNotification;
  timestamp?: string;
  limit?: string;
  sortField?: string;
  sortOrder?: SortDirection;
  skip?: string;
}

export interface IUserNotificationService {
  insert(
    notificationTemplate: any,
    userNotification: IUserNotification,
  ): Promise<any>;
  getNotifications(filter: UserNotificationRequest): Promise<any>;
  getNotificationsCount(status: EnumStatusNotification): Promise<any>;
  updateStatus(
    ids: string[],
    status: EnumStatusNotification,
    after?: Date,
  ): Promise<void>;
}
