import { ComponentOptions, S9QueryCriteria } from '@april9/stack9-sdk';

export const getPropValue = (propPathOrName: string, obj: any) => {
  const names = propPathOrName.split('.');
  return names.reduce((prev, currKey) => {
    if (!prev) return undefined;

    return prev[currKey];
  }, obj);
};

export const getComponentOptionsLabelValue = (
  componentOptions?: ComponentOptions,
) => {
  const valuePropName = componentOptions?.value || 'id';
  const labelPropName = componentOptions?.label || 'name' || 'id';

  return { valuePropName, labelPropName };
};

export const parseWhereVariables = (
  filterWhere: S9QueryCriteria | undefined,
  formData: any | undefined,
) => {
  if (!filterWhere) return undefined;

  if (!formData) return filterWhere;

  let query = JSON.stringify(filterWhere);

  const regexp = RegExp(/":[a-z0-9_]{2,}"/g);

  const variables = query.match(regexp);

  if (!variables?.length) return filterWhere;

  variables.forEach(item => {
    const key = item.substring(2, item.length - 1);
    const value = formData[key] || '';

    if (typeof value === 'string') {
      query = query.replace(item, `"${value}"`);
    } else {
      query = query.replace(item, value);
    }
  });

  return JSON.parse(query);
};
