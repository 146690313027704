import { DecodedToken, EntityInterface } from '@april9/stack9-sdk';

export interface AuthState<TUser extends EntityInterface = EntityInterface> {
  error?: Error;
  isAuthenticated: boolean;
  isLoading: boolean;
  user?: TUser;
  decodedToken?: DecodedToken;
}

/**
 * The initial auth state.
 */
export const initialAuthState: AuthState = {
  isAuthenticated: false,
  isLoading: true,
};
