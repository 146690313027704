export enum HookOperation {
  create = 'create',
  update = 'update',
  delete = 'delete',
  proceedToStep = 'proceedToStep',
  returnStep = 'returnStep',
  approveWorkflow = 'approveWorkflow',
  takeOwnership = 'takeOwnership',
  rejectWorkflow = 'rejectWorkflow',
}
