import { DatabaseFieldTypes } from './FieldItem';

export enum OutJoinMatch {
  New,
  Update,
  Delete,
}

export interface IEntitySchemaValidationRules {
  required?: boolean;
  min?: number;
  minLength?: number;
  max?: number;
  maxLength?: number;
  pattern?: string;
}

export interface IEntitySchemaField {
  key: string;
  label: string;
  description: string | null;
  placeholder: string | null;
  db_type: DatabaseFieldTypes;
  ui_component: string;
  ui_component_options: string | null;
  validation_rules: IEntitySchemaValidationRules | string;
  related_entity: string;
  _active: boolean;
}

export interface IEntityType {
  key: string;
  name: string;
  plural_name: string;
  has_workflow: boolean;
  allow_comments: boolean;
  allow_tasks: boolean;
  is_native: boolean;
  form_layout: string;
  grid_settings: string;
  export_settings: string;
  json_definition: string;
  workflow_definition: string | null;
}

export interface IEntityTypeField extends IEntitySchemaField {
  entity_type_key: string;
  entity_type_id: number;
  index: boolean;
  _active: boolean;
  validation_rules: string;
}

export interface IEntityTypeHook {
  entity_type_key: string;
  entity_type_id: number;
  key: string;
  type: string;
  hook_type: string;
  function_name: string | null;
  webhook_id: string | null;
  workflow_id: string | null;
  action_key: string | null;
  description: string | null;
  _active: boolean;
}

export interface IEntityTypeFieldRelationship {
  entity_type_key: string;
  entity_type_id: number;
  primary_entity: string;
  primary_entity_field: string;
  related_entity: string;
  related_entity_field?: string;
  key: string;
  name: string;
  associative_table: string;
  type: string;
  filter_where?: string;
}

export function merger<LeftRow, RightRow>(
  left: LeftRow,
  right: RightRow,
): [LeftRow, RightRow, OutJoinMatch] {
  if (left === undefined) {
    return [left, right, OutJoinMatch.Delete];
  }

  if (right === undefined) {
    return [left, right, OutJoinMatch.New];
  }
  return [left, right, OutJoinMatch.Update];
}
