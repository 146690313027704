export function serialize(json: any) {
  return Object.keys(json)
    .filter(
      key =>
        (!Array.isArray(json[key]) && json[key]) ||
        (Array.isArray(json[key]) && json[key].length > 0),
    )
    .map(key => `${key}=${encodeURIComponent(json[key])}`)
    .join('&');
}

export function deserializeQuery(query: string): any {
  if (!query) {
    return null;
  }

  let q = decodeURI(query);
  if (q.indexOf('?') === 0) {
    q = q.slice(1);
  }
  const pairs = q.split('&');
  const result: any = {};
  pairs.forEach(pair => {
    const keyValue = pair.split('=');
    result[keyValue[0]] = decodeURIComponent(keyValue[1] || '');
  });
  return result;
}
